














import { Component, Vue } from "vue-property-decorator"
import posts from "@/autoblog/auto-posts"

const options = {
  month: "long",
  year: "numeric",
  day: "numeric",
}

@Component({
  metaInfo: {
    title: "Discord4J - The Blog Homepage",
  },
})
export default class Blog extends Vue {
  public posts = posts

  public formatDate(dateString: string): string {
    const date = new Date(dateString)
    return date.toLocaleDateString("en-US", options)
  }
}
