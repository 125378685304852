const posts = [
  {
    id: "using-docker-containerization",
    permalink: "/blog/using-docker-containerization",
    title: "Discord4J - Using Docker to Containerize the Website",
    description:
      "A post describing  the technical aspects of migrating our server to a fully containerized setup",
    date: "Sat Jun 09 2018 21:43:00 GMT+0000 (Coordinated Universal Time)",
    tags: ["devops", "docker", "docker-compose", "website"],
  },
]

export default posts
